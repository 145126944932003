
import { 
  IonPage, 
  IonHeader, 
  IonToolbar, 
  IonTitle, 
  IonContent,
  IonButtons,
  IonButton,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonNote,
  IonReorder,
  IonReorderGroup,
  IonItemSliding,
  IonItemOptions,
  IonItemOption,


  onIonViewDidEnter,
 } from '@ionic/vue';

import { 
  checkmark, 
  addCircle,
  reorderFour,
  ellipseOutline,
  checkmarkCircleOutline,
  checkmarkCircle,
  create,
  shuffle,
  ellipsisVertical,
  trashOutline,
  createOutline,
  chevronBack,
  close,
}  from 'ionicons/icons';

import { defineComponent, ref, computed } from '@vue/runtime-core';
import { useRouter } from 'vue-router';
import { ItemOrder, TaskGroup, apiEndpoints } from '@/lib/api-endpoints';
import { useStore } from '@/store';
import t from '@/translation'
import { showModal } from '@/lib/modal';
import ExportButton from '@/components/ExportButton.vue';

export default  defineComponent({
  name: 'WeddingInfo',
  components: { 
    IonPage, 
    IonHeader, 
    IonToolbar, 
    IonTitle, 
    IonContent,
    IonButtons,
    IonButton,
    //IonBackButton,
    IonIcon,
    IonList,
    //IonItemDivider,
    IonItem,
    IonLabel,
    //IonCheckbox,
    //IonSpinner,
    IonNote,
    IonReorder,
    IonReorderGroup,
    //IonSelectOption,
    //IonMenu,
    //IonMenuButton,
    //IonPopover,
    //IonText,
    IonItemSliding,
    IonItemOptions,
    IonItemOption,
    

    //MInput,
    //MSelect,
    //MPassword,
    ExportButton,
  },
  setup(){

    const store = useStore();
    const router = useRouter();

    const updateId = ref(-1);

    const currentAction = ref("");
    
    

    const groupProgress = computed(()=>{
      const result = new Map<number, {total: number; done: number}>()
      for (const group of store.state.taskGroups){
          result.set(group.id, {total: 0, done: 0});
      }

      for (const task of store.state.tasks){
          const item = result.get(task.task_group.id);
          item!.total += 1;
          item!.done += task.task_status; // 0 is not done, 1 is done for task status. 
          result.set(task.task_group.id, item!);
      }
      return result;
    });

    let reorderList: number[];
    function createCategoryOrder(){
      // create a set of lists for the reorder operation
      reorderList = [];
      for (const group of store.state.taskGroups){
        reorderList.push(group.id);
      }
    }

    onIonViewDidEnter(()=>{
      console.log("ViewDidEnter Start");
      currentAction.value = "";
      store.dispatch('getTaskGroups').then(()=>{
        store.dispatch('getTasks').then(()=>{
          createCategoryOrder();
        });
      });
      console.log("ViewDidEnter Done");
    });

    function setCurrentAction(action: string){
      if (action == 'create'){
        router.push('/tabs/todo/list');
      } else {
        currentAction.value = action;
      }
    }

    function editClicked(group: TaskGroup){
      setCurrentAction("edit");
      updateId.value = group.id;
    }

    function itemClicked(group: TaskGroup){
      router.push(`/tabs/todo/list/${group.id}`)
    }

    function deleteClicked(id: number){
      // check if there are tasks associated with this category
      if (store.state.tasks.findIndex((value) => value.task_group.id == id) != -1){
        showModal(t("Can't Delete"), t("The selected category contains tasks.  Please delete all tasks in a category before trying to delete the category itself."));
        return;
      }

      store.dispatch('deleteTaskGroup', id);
    }

    function doReorder(evt: CustomEvent){
      const [el] = reorderList.splice(evt.detail.from, 1); 
      reorderList.splice(evt.detail.to, 0, el);
      evt.detail.complete();
    }

    

    function dismissActions(){
      // performa any clean up / sync operations for each action
      switch (currentAction.value){
        case("reorder"):{
          const categoryOrder: ItemOrder[] = [];
          let sortOrder = 10;
          for (const groupId of reorderList){
            // eslint-disable-next-line @typescript-eslint/camelcase
            categoryOrder.push({id: groupId, sort_order: sortOrder});
            sortOrder += 10;
          }
          store.dispatch('reorderTaskGroups', categoryOrder);
        }
      }
      currentAction.value = "";
      updateId.value = -1;
    }

    

    return {

      currentAction,
      groupProgress,
      
      checkmark,
      addCircle,
      reorderFour,
      create,
      shuffle,
      ellipsisVertical,
      trashOutline,
      createOutline,
      chevronBack,
      ellipseOutline,
      checkmarkCircleOutline,
      checkmarkCircle,
      close,

      router,
      itemClicked,
      editClicked,
      deleteClicked,
      doReorder,
      //openPopover,
      dismissActions,
      setCurrentAction,
      updateId,
      store,
      apiEndpoints,
    }
  }
});
